<template>
  <ClientOnly>
    <Teleport to="body">
      <!-- Generic Add to trolley modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="add-to-trolley-generic"
          v-model:visible="trolleyStore.preview_overlay_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalAddToTrolley
            v-if="trolleyStore.previewed_product"
            :collection-availability="trolleyStore.collection_availability"
            :selected-variant-label="
              trolleyStore.previewed_product.pack_size ||
              trolleyStore.previewed_product.name
            "
            :loading="trolleyStore.is_updating_item_details_in_add_to_trolley"
            :add-to-trolley-cta-loading="
              trolleyStore.add_to_trolley_cta_loading
            "
            :data-testid="`add-to-trolley-modal-for-product-${trolleyStore.previewed_product.code}`"
            :show-near-by-stock-finder="false"
            @set-branch="handleSetBranchFromAddToTrolleyModal"
            @add-to-trolley="(q: number) => handleAddToTrolleyAction(q)"
            @close="trolleyStore.preview_overlay_visible = false"
            @click-variants="handleVariantClickFromAddToTrolleyModal"
            @notify="(code, name) => handleNotifyMe(code, name)"
          >
          </TsModalAddToTrolley>
        </TsDrawer>
      </template>
      <template v-else-if="isDesktop">
        <TsModal
          data-testid="add-to-trolley-generic"
          v-model:visible="trolleyStore.preview_overlay_visible"
          append-class="max-h-[80vh] bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalAddToTrolley
              v-if="trolleyStore.previewed_product"
              :collection-availability="trolleyStore.collection_availability"
              :selected-variant-label="
                trolleyStore.previewed_product.pack_size ||
                trolleyStore.previewed_product.name
              "
              :loading="trolleyStore.is_updating_item_details_in_add_to_trolley"
              :add-to-trolley-cta-loading="
                trolleyStore.add_to_trolley_cta_loading
              "
              :data-testid="`add-to-trolley-modal-for-product-${trolleyStore.previewed_product.code}`"
              :show-near-by-stock-finder="false"
              @set-branch="handleSetBranchFromAddToTrolleyModal"
              @add-to-trolley="(q: number) => handleAddToTrolleyAction(q)"
              @close="trolleyStore.preview_overlay_visible = false"
              @click-variants="handleVariantClickFromAddToTrolleyModal"
              @notify="(code, name) => handleNotifyMe(code, name)"
            />
          </template>
        </TsModal>
      </template>

      <!-- Collection modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="collection-preview"
          v-model:visible="trolleyStore.preview_overlay_collection_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyCollection
            :loading="
              trolleyStore.currentTrolleyAction === 'Collection' ||
              trolleyStore.currentTrolleyAction === 'NextDayCollection'
            "
            :primary-tag="trolleyStore.trolley_modal_primary_tag"
            primary-tag-variant="success"
            @set-branch="handleSetBranchFromCollectionModal"
            @close="trolleyStore.preview_overlay_collection_visible = false"
            @notify="(code, name) => handleNotifyMe(code, name)"
            @go-to-trolley="handleTrolleyPageNavigation"
            @find-stock="(requiredQuantity) => findNearByStock(requiredQuantity)"
            @add-recommendation-to-trolley="(item) => addRecommendationToTrolleyFromPreviewModal(item)"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="collection-preview"
          v-model:visible="trolleyStore.preview_overlay_collection_visible"
          append-class="bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalTrolleyCollection
              :loading="
                trolleyStore.currentTrolleyAction === 'Collection' ||
                trolleyStore.currentTrolleyAction === 'NextDayCollection'
              "
              :primary-tag="trolleyStore.trolley_modal_primary_tag"
              primary-tag-variant="success"
              @set-branch="handleSetBranchFromCollectionModal"
              @close="trolleyStore.preview_overlay_collection_visible = false"
              @notify="(code, name) => handleNotifyMe(code, name)"
              @go-to-trolley="handleTrolleyPageNavigation"
              @find-stock="(requiredQuantity) => findNearByStock(requiredQuantity)"
              @add-recommendation-to-trolley="(item) => addRecommendationToTrolleyFromPreviewModal(item)"
            />
          </template>
        </TsModal>
      </template>

      <!-- Delivery modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="delivery-modal"
          v-model:visible="trolleyStore.preview_overlay_delivery_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyDelivery
            :loading="trolleyStore.currentTrolleyAction === 'Delivery'"
            :primary-tag="trolleyStore.trolley_modal_primary_tag"
            @notify="(code, name) => handleNotifyMe(code, name)"
            @close="trolleyStore.preview_overlay_delivery_visible = false"
            @go-to-trolley="handleTrolleyPageNavigation"
            @add-recommendation-to-trolley="(item) => addRecommendationToTrolleyFromPreviewModal(item)"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="delivery-modal"
          v-model:visible="trolleyStore.preview_overlay_delivery_visible"
          append-class="bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalTrolleyDelivery
              :loading="trolleyStore.currentTrolleyAction === 'Delivery'"
              :primary-tag="trolleyStore.trolley_modal_primary_tag"
              @notify="(code, name) => handleNotifyMe(code, name)"
              @close="trolleyStore.preview_overlay_delivery_visible = false"
              @go-to-trolley="handleTrolleyPageNavigation"
              @add-recommendation-to-trolley="(item) => addRecommendationToTrolleyFromPreviewModal(item)"
            />
          </template>
        </TsModal>
      </template>

      <!-- Delivery variants modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="delivery-variants"
          v-model:visible="trolleyStore.delivery_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyDeliveryVariants
            :label="isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'"
            :is-loading="variantAddToTrolleyLoading"
            :append-class="
              twJoin(
                'mt-4 gap-1.5 mx-auto max-w-sm',
                isVariantsAddedToTrolley &&
                  'bg-success hover:bg-success-hover border-success text-idle-white'
              )
            "
            :disabled="isVariantsAddedToTrolley"
            @notify="(selectedVariant) => handleNotifyMe(selectedVariant.code, selectedVariant.full_name || selectedVariant.name)"
            @close="trolleyStore.delivery_variants_modal_visible = false"
            @add-all-to-trolley="
              (variantsToAdd) => addAllDeliveryVariantsToTrolley(variantsToAdd)
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="delivery-variants"
          append-class="bg-transparent overflow-hidden"
          v-model:visible="trolleyStore.delivery_variants_modal_visible"
        >
          <template #modal-content>
            <TsModalTrolleyDeliveryVariants
              :label="isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'"
              :is-loading="variantAddToTrolleyLoading"
              :append-class="
                twJoin(
                  'mt-4 gap-1.5 mx-auto max-w-sm',
                  isVariantsAddedToTrolley &&
                    'bg-success hover:bg-success-hover border-success text-idle-white'
                )
              "
              :disabled="isVariantsAddedToTrolley"
              @notify="(selectedVariant) => handleNotifyMe(selectedVariant.code, selectedVariant.full_name || selectedVariant.name)"
              @close="trolleyStore.delivery_variants_modal_visible = false"
              @add-all-to-trolley="
                (variantsToAdd) => addAllDeliveryVariantsToTrolley(variantsToAdd)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Collection variants modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="collection-variants"
          v-model:visible="trolleyStore.collection_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyCollectionVariants
            :label="isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'"
            :is-loading="variantAddToTrolleyLoading"
            :append-class="
              twJoin(
                'mt-4 gap-1.5 mx-auto max-w-sm',
                isVariantsAddedToTrolley &&
                  'bg-success hover:bg-success-hover border-success text-idle-white'
              )
            "
            :disabled="isVariantsAddedToTrolley"
            @close="trolleyStore.collection_variants_modal_visible = false"
            @notify="(selectedVariant) => handleNotifyMe(selectedVariant.code, selectedVariant.full_name || selectedVariant.name)"
            @add-all-to-trolley="
              (variantsToAdd) => addAllCollectionVariantsToTrolley(variantsToAdd)
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="collection-variants"
          append-class="bg-transparent overflow-hidden"
          v-model:visible="trolleyStore.collection_variants_modal_visible"
        >
          <template #modal-content>
            <TsModalTrolleyCollectionVariants
              :label="isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'"
              :is-loading="variantAddToTrolleyLoading"
              :append-class="
                twJoin(
                  'mt-4 gap-1.5 mx-auto max-w-sm',
                  isVariantsAddedToTrolley &&
                    'bg-success hover:bg-success-hover border-success text-idle-white'
                )
              "
              :disabled="isVariantsAddedToTrolley"
              @close="trolleyStore.collection_variants_modal_visible = false"
              @notify="(selectedVariant) => handleNotifyMe(selectedVariant.code, selectedVariant.full_name || selectedVariant.name)"
              @add-all-to-trolley="
                (variantsToAdd) => addAllCollectionVariantsToTrolley(variantsToAdd)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- OOS Notification enable modal -->
      <template v-if="isMobile">
        <TsDrawer
          v-model:visible="productStore.subscribe_notification_modal_visible"
          data-testid="subscribe-notification-modal"
          position="bottom"
          append-class="bg-transparent overflow-hidden min-h-48"
        >
          <TsModalOutOfStockSubscription
            @close="productStore.subscribe_notification_modal_visible = false"
            @subscribe="productStore.subscribe_notification_modal_visible = false"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          v-model:visible="productStore.subscribe_notification_modal_visible"
          data-testid="subscribe-notification-modal"
          append-class="max-w-[450px]"
        >
          <template #modal-content>
            <TsModalOutOfStockSubscription
              @close="productStore.subscribe_notification_modal_visible = false"
              @subscribe="productStore.subscribe_notification_modal_visible = false"
            />
          </template>
        </TsModal>
      </template>

      <!-- OOS subscription confirmation modal -->
      <template v-if="isMobile">
        <TsDrawer
          v-model:visible="productStore.subscription_successful_modal_visible"
          data-testid="subscription-successful"
          position="bottom"
          append-class="bg-transparent overflow-hidden min-h-48"
        >
        <TsModalSubscriptionConfirmation
              :productName="productStore.notify_product_name"
              :email="productStore.notify_user_email"
              @close="productStore.subscription_successful_modal_visible = false"
            />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          v-model:visible="productStore.subscription_successful_modal_visible"
          data-testid="subscription-successful"
          append-class="max-w-[450px]"
        >
          <template #modal-content>
            <TsModalSubscriptionConfirmation
              :productName="productStore.notify_product_name"
              :email="productStore.notify_user_email"
              @close="productStore.subscription_successful_modal_visible = false"
            />
          </template>
        </TsModal>
      </template>

      <!-- Product card variants modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="product-variant-modal"
          v-model:visible="trolleyStore.product_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalProductVariants
            v-if="trolleyStore.previewed_product_v2 || trolleyStore.previewed_product"
            :main-product="trolleyStore.previewed_product_v2 || trolleyStore.previewed_product"
            :variants="trolleyStore.previewed_product_variations"
            @close="trolleyStore.product_variants_modal_visible = false"
            @select="(selectedVariant) => handleProductVariantSelection(selectedVariant)"
            @notify="(selectedVariant) => handleNotifyMe(selectedVariant.code, selectedVariant.full_name || selectedVariant.name)"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="product-variant-modal"
          v-model:visible="trolleyStore.product_variants_modal_visible"
          append-class="bg-transparent overflow-hidden max-w-[450px]"
        >
          <template #modal-content>
            <TsModalProductVariants
              v-if="trolleyStore.previewed_product_v2 || trolleyStore.previewed_product"
              :main-product="trolleyStore.previewed_product_v2 || trolleyStore.previewed_product"
              :variants="trolleyStore.previewed_product_variations"
              @close="trolleyStore.product_variants_modal_visible = false"
              @select="
                (selectedVariant) => handleProductVariantSelection(selectedVariant)
              "
              @notify="(selectedVariant) => handleNotifyMe(selectedVariant.code, selectedVariant.full_name || selectedVariant.name)"
            />
          </template>
        </TsModal>
      </template>

      <!-- Product card variants with both CTAs modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid=""
          v-model:visible="trolleyStore.preview_collection_delivery_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <TsModalTrolleyCollectionDeliveryCTA
            @add-to-trolley="
              (productCode, quantity, channel) =>
                handleAddToTrolleyFromBothCTAModal(productCode, quantity, channel)
            "
            @notify="handleNotifyMe(trolleyStore.previewed_product_v2.code , trolleyStore.previewed_product_v2.full_name || trolleyStore.previewed_product_v2.name)"
            @set-branch="handleSetBranchFromBothCTAModal"
            :loading-cta="trolleyStore.activeTrolleyCTAInModal"
            :loading="trolleyStore.currentTrolleyAction === 'BothCTA'"
            v-if="trolleyStore.previewed_product_v2"
            :product="trolleyStore.previewed_product_v2"
            v-model:quantity="selectedProductVariantQuantity"
            @close="trolleyStore.preview_collection_delivery_modal_visible = false"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid=""
          append-class="max-w-[450px]"
          v-model:visible="trolleyStore.preview_collection_delivery_modal_visible"
        >
          <template #modal-content>
            <TsModalTrolleyCollectionDeliveryCTA
            @add-to-trolley="
              (productCode, quantity, channel) =>
                handleAddToTrolleyFromBothCTAModal(productCode, quantity, channel)
            "
            @notify="handleNotifyMe(trolleyStore.previewed_product_v2.code , trolleyStore.previewed_product_v2.full_name || trolleyStore.previewed_product_v2.name)"
            @set-branch="handleSetBranchFromBothCTAModal"
            :loading-cta="trolleyStore.activeTrolleyCTAInModal"
            :loading="trolleyStore.currentTrolleyAction === 'BothCTA'"
            v-if="trolleyStore.previewed_product_v2"
            :product="trolleyStore.previewed_product_v2"
            v-model:quantity="selectedProductVariantQuantity"
            @close="trolleyStore.preview_collection_delivery_modal_visible = false"
          />
          </template>
        </TsModal>
      </template>

      <!-- Confirmation modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="trolley-multiple-items-confirmation-drawer-mobile"
          v-model:visible="trolleyStore.multiple_items_confirmation_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <TsModalTrolleyMultipleItemsConfirmation
            :products="trolleyStore.variant_items_added_to_trolley"
            :branch="branchStore.lastSavedBranch?.name"
            @close="trolleyStore.multiple_items_confirmation_modal_visible = false"
            @go-to-trolley="handleTrolleyPageNavigation"
            @add-recommendation-to-trolley="
              (item) => addRecommendationToTrolleyFromConfirmationModal(item)
            "
          />
        </TsDrawer>
      </template>
      <template v-else-if="isDesktop">
        <TsModal
          data-testid="trolley-multiple-items-confirmation-modal-desktop"
          v-model:visible="trolleyStore.multiple_items_confirmation_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <template #modal-content>
            <TsModalTrolleyMultipleItemsConfirmation
              :products="trolleyStore.variant_items_added_to_trolley"
              :branch="branchStore.lastSavedBranch?.name"
              @close="trolleyStore.multiple_items_confirmation_modal_visible = false"
              @go-to-trolley="handleTrolleyPageNavigation"
              @add-recommendation-to-trolley="
                (item) => addRecommendationToTrolleyFromConfirmationModal(item)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Nearby stock search modal and actions -->
      <TsFindStockNearbyActions v-if="trolleyStore.previewed_product_v2" :product="trolleyStore.previewed_product_v2" data-testid="PLP-nearby-stock-finder" />

      <!-- Product comparison drawer -->
      <template v-if="compareStore.isCompareRowVisible">
        <div
          class="fixed bottom-0 left-0 right-0 bg-white z-30 shadow-[-2px_-3px_7.5px_2px_#02294E29]"
        >
          <TsCompareRow
            :items="compareStore.checkedProduts"
            @remove-item="removeItemFromComparison"
            @clear-all="handleClearAll"
          />
        </div>
      </template>
      <TsDrawer
        data-testid=""
        position="bottom"
        v-model:visible="compareStore.enableExpand"
        append-class="bg-natural-soft-white overflow-y-auto h-full"
      >
        <TsSection
          v-if="compareStore.checkedProduts.length && compareStore.enableExpand"
          container-class="max-md:px-0"
        >
          <TsSectionProductCompare @close="compareStore.enableExpand = false" />
        </TsSection>
      </TsDrawer>

      <template v-if="isDesktop">
        <TsModal
          data-testid="trolley-multiple-items-confirmation-modal-desktop"
          v-model:visible="savelistStore.modal_savelist_from_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <template #modal-content>
            <TsModalSaveListFormV2
              v-model:visible="savelistStore.modal_savelist_from_visible"
          />
          </template>
        </TsModal>
      </template>
      
    </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import { DeliveryMethodCodes } from "~/assets/constants/ecomApi";
import type { Branch } from "~/types/ecom/branch.type";
import type { Product } from "~/types/ecom/product/product.type";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";
import type { TrolleyLineRequest } from "~/types/ecom/trolley/trolley.type";

const localePath = useLocalePath();

const { isMobile, isDesktop } = useDevice();
const trolleyStore = useTrolleyStore();
const productStore = useProductStore();
const branchStore = useBranchStore();
const stockStore = useStockStore();
const compareStore = useCompareStore();
const savelistStore = useSavelistStore();

const removeItemFromComparison = (item: Product) => {
  compareStore.checkedProduts = compareStore.checkedProduts?.filter(
    (p) => p.code !== item.code
  );
  if (!compareStore.checkedProduts.length) compareStore.isCompareRowVisible = false;
};

const handleClearAll = () => {
  compareStore.clearCompareProducts();
};

function hideAllListingPageModals() {
  compareStore.enableExpand = false;
  compareStore.isCompareRowVisible = false;
  trolleyStore.preview_overlay_delivery_visible = false;
  trolleyStore.preview_overlay_collection_visible = false;
  trolleyStore.multiple_items_confirmation_modal_visible = false;
  trolleyStore.product_variants_modal_visible = false;
  trolleyStore.delivery_variants_modal_visible = false;
  trolleyStore.collection_variants_modal_visible = false;
  trolleyStore.preview_collection_delivery_modal_visible = false;
  trolleyStore.preview_overlay_visible = false;
}

const variantAddToTrolleyLoading = ref(false);
const isVariantsAddedToTrolley = ref(false);
const selectedProductVariantQuantity = ref(1);

/* Helpers */
function calculateTrolleySummaryOnTrolleyRoute(){
  const route = useRoute();
  if (route.path.includes("trolley")) trolleyStore.setOrderTotals();
}

/* Recommendation actions */
async function addRecommendationToTrolleyFromConfirmationModal(addOnItem: Product) {
  trolleyStore.variant_items_added_to_trolley = [
    {
      ...addOnItem,
      quantity: 1,
      channel: TrolleyChannel.Delivery,
    },
  ];
  useRootStore().toastSuccess(['Item added to cart for delivery'], 'top-right');
  await trolleyStore.dispatchItemToTrolley(
    addOnItem.code,
    1,
    TrolleyChannel.Delivery,
    DeliveryMethodCodes.Delivery
  );
  calculateTrolleySummaryOnTrolleyRoute();
}

async function addRecommendationToTrolleyFromPreviewModal(addOnItem: Product) {
  trolleyStore.previewed_product_v2 = addOnItem;
  trolleyStore.trolley_modal_primary_tag = useTranslation('delivery', 'Levering');
  calculateTrolleySummaryOnTrolleyRoute();
}

/* OOS notification actions */
async function handleNotifyMe(
    productCode: string | undefined,
    productName: string | undefined
) {
  const authStore = useAuthStore();
  if (!authStore.is_authenticated) {
    await navigateTo(localePath("/auth/signin"));
    return;
  }
  hideAllListingPageModals();
  productStore.setProductCodeforNotification(productCode, productName);
  productStore.subscribe_notification_modal_visible = true;
}

/* Add to trolley - generic */
async function handleAddToTrolleyAction(quantity: number) {
  if (!trolleyStore.previewed_product) return;

  useMonetateStore().getProductAddons();

  trolleyStore.variant_items_added_to_trolley = [];
  trolleyStore.add_to_trolley_cta_loading = true;

  // Direct ship delivery
  if (trolleyStore.previewed_product?.direct_ship) {
    trolleyStore.addToTrolleyForDirectship(trolleyStore.previewed_product.code, quantity);
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.Directship,
      },
    ];
  }

  // Next day collection
  else if (
    trolleyStore.previewed_product_selected_channel === TrolleyChannel.Collection &&
    trolleyStore.collection_availability?.status === "NextDayCollection"
  ) {
    trolleyStore.addToTrolleyForNextDayCollection(
      trolleyStore.previewed_product.code,
      quantity
    );
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.NextDayCollection,
      },
    ];
  }

  // Collection
  else if (
    trolleyStore.previewed_product_selected_channel === TrolleyChannel.Collection
  ) {
    if (checkItemForNextDayCollection()) {
      trolleyStore.addToTrolleyForNextDayCollection(
        trolleyStore.previewed_product.code,
        quantity
      );
      trolleyStore.variant_items_added_to_trolley = [
        {
          ...trolleyStore.previewed_product,
          quantity,
          channel: TrolleyChannel.NextDayCollection,
        },
      ];
    } else
      await trolleyStore.addToTrolleyForCollection(
        trolleyStore.previewed_product.code,
        quantity
      );
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.Collection,
      },
    ];
  }

  // Delivery
  else {
    await trolleyStore.addToTrolleyForDelivery(
      trolleyStore.previewed_product.code,
      quantity
    );
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.Delivery,
      },
    ];
  }

  trolleyStore.add_to_trolley_cta_loading = false;
  getMonetateAddons();

  setTimeout(() => {
    trolleyStore.preview_overlay_visible = false;
    trolleyStore.multiple_items_confirmation_modal_visible = true;
  }, 500);

  calculateTrolleySummaryOnTrolleyRoute();
}

function checkItemForNextDayCollection() {
  if (!trolleyStore.previewed_product) return false;
  // check if item already exists in trolley
  const trolleyCollectionLineItem = trolleyStore.findTrolleyItemByProductCode(
    trolleyStore.previewed_product.code
  );
  if (!trolleyCollectionLineItem) return false;
  const collectionStock = trolleyStore.previewed_product.stockDetails?.collection ?? 0;
  const deliveryStock = trolleyStore.previewed_product.stockDetails?.delivery ?? 0;
  const quantityToAdd = trolleyStore.previewed_product_selected_quantity;
  const totalQuantity = trolleyCollectionLineItem.quantity + quantityToAdd;
  // if total quantity exceeds collection stock and is in trolley
  if (totalQuantity > collectionStock && deliveryStock > 0) return true;
  return false;
}

async function handleVariantClickFromAddToTrolleyModal() {
  trolleyStore.preview_overlay_visible = false;
  trolleyStore.previewed_product_variations = [];
  trolleyStore.product_variants_modal_visible = true;
  await trolleyStore.getProductVariants(
    trolleyStore.previewed_product?.variations as string[]
  );
}

// common method to add multiple product variants to trolley
async function addVariantsToTrolley(variantsToAdd: TrolleyLineRequest[]) {
  variantAddToTrolleyLoading.value = true;
  await trolleyStore.addMultipleItemsToTrolley(variantsToAdd);
  getMonetateAddons();
  variantAddToTrolleyLoading.value = false;
  isVariantsAddedToTrolley.value = true;
}

async function addAllCollectionVariantsToTrolley(
  collectionVariantsToAdd: TrolleyLineRequest[]
) {
  await addVariantsToTrolley(collectionVariantsToAdd);
  setTimeout(() => {
    isVariantsAddedToTrolley.value = false;
    setVariantItemsAddedToTrolley(collectionVariantsToAdd);
    trolleyStore.collection_variants_modal_visible = false;
    trolleyStore.multiple_items_confirmation_modal_visible = true;
    trolleyStore.collection_product_variants = [];
  }, 1000);
}

async function addAllDeliveryVariantsToTrolley(
  deliveryVariantsToAdd: TrolleyLineRequest[]
) {
  await addVariantsToTrolley(deliveryVariantsToAdd);
  setTimeout(() => {
    isVariantsAddedToTrolley.value = false;
    setVariantItemsAddedToTrolley(deliveryVariantsToAdd);
    trolleyStore.delivery_variants_modal_visible = false;
    trolleyStore.multiple_items_confirmation_modal_visible = true;
    trolleyStore.delivery_product_variants = [];
  }, 1000);
}

function setVariantItemsAddedToTrolley(items: TrolleyLineRequest[]) {
  trolleyStore.variant_items_added_to_trolley = Object.values(
    trolleyStore.trolley_line_items!
  )
    .flatMap((val) => val)
    .filter((line) => items.some((item) => item.product_code === line.product_code))
    .map((line) => {
      const matchingItem = items.find((item) => item.product_code === line.product_code);
      return {
        ...line.product,
        quantity: matchingItem!.quantity,
        channel: line.channel,
      } as TrolleyItemVariant;
    });
}

function handleProductVariantSelection(selectedVariant: Product) {
  trolleyStore.product_variants_modal_visible = false;
  trolleyStore.previewed_product_v2 = selectedVariant;
  trolleyStore.preview_collection_delivery_modal_visible = true;
  // reset states
  selectedProductVariantQuantity.value = 1;
  trolleyStore.currentTrolleyAction = "";
  trolleyStore.activeTrolleyCTAInModal = "";
}

async function onChangeBranchFromTrolleyModals(modalType?: "collection" | "bothCTA") {
  // open the branch selector pop up
  branchStore.branchSelectorModalVisible = true;
  const unwatch = watch(
    () => [branchStore.lastSavedBranch],
    async () => {
      // clear watch handlers / side effects
      unwatch();
      if (modalType === "collection") {
        trolleyStore.preview_overlay_collection_visible = true;
        trolleyStore.currentTrolleyAction = "Collection";
      } else if (modalType === "bothCTA") {
        trolleyStore.preview_collection_delivery_modal_visible = true;
        trolleyStore.currentTrolleyAction = "BothCTA";
      }
      // recalculate stock for the previewed product
      const stockStore = useStockStore();
      const [productWithUpdatedStock] = await stockStore.getStockForProducts([
        trolleyStore.previewed_product_v2?.code!,
      ]);
      trolleyStore.previewed_product_v2 = productWithUpdatedStock;
      trolleyStore.currentTrolleyAction = "";
    }
  );
}

async function handleSetBranchFromBothCTAModal() {
  trolleyStore.preview_collection_delivery_modal_visible = false;
  await onChangeBranchFromTrolleyModals("bothCTA");
}

async function handleSetBranchFromCollectionModal() {
  trolleyStore.preview_overlay_collection_visible = false;
  await onChangeBranchFromTrolleyModals("collection");
}

async function handleSetBranchFromAddToTrolleyModal() {
  trolleyStore.preview_overlay_visible = false;
  branchStore.branchSelectorModalVisible = true;

  const unwatch = watch(
    () => [branchStore.lastSavedBranch],
    async () => {
      unwatch();
      trolleyStore.preview_overlay_visible = true;
      if (!trolleyStore.previewed_product) return;
      trolleyStore.collection_stock_loading = true;

      trolleyStore.collection_availability =
        await trolleyStore.evaluateCollectionAvailability(
          trolleyStore.previewed_product,
          trolleyStore.previewed_product_selected_quantity,
          true
        );

      trolleyStore.collection_stock_loading = false;
    }
  );
}

async function handleAddToTrolleyFromBothCTAModal(
  productCode: string,
  quantity: number,
  channel: TrolleyChannel
) {
  trolleyStore.variant_items_added_to_trolley = [];

  if (channel === TrolleyChannel.Collection) await trolleyStore.listenToBranchSet();

  trolleyStore.activeTrolleyCTAInModal = TrolleyChannel[channel];

  await trolleyStore.dispatchItemToTrolley(
    productCode,
    quantity,
    channel,
    //@ts-ignore
    DeliveryMethodCodes[TrolleyChannel[channel]]
  );
  getMonetateAddons();

  trolleyStore.activeTrolleyCTAInModal = "";
  trolleyStore.preview_collection_delivery_modal_visible = false;
  trolleyStore.multiple_items_confirmation_modal_visible = true;
  trolleyStore.variant_items_added_to_trolley = [
    { ...trolleyStore.previewed_product_v2!, quantity, channel },
  ];
  selectedProductVariantQuantity.value = 1;
}

//-------------------- Find stock near by feature
async function findNearByStock(requiredQuantity: number) {
  if (!trolleyStore.previewed_product_v2) return;
  const currentBranch = branchStore.lastSavedBranch;

  if (!currentBranch) return;

  trolleyStore.preview_overlay_collection_visible = false;
  
  // reset old statuses
  stockStore.nearby_stock_search_branch_choice = null;
  branchStore.localitySearchInput = currentBranch.name;
  branchStore.autoCompleteSuggestions = [];
  branchStore.resetStatuses();

  stockStore.stock_finder_modal_visible = true;

  stockStore.is_nearby_stock_search_loading = true;

  await handleNearByStockSearch({
    latitude: currentBranch.geolocation.latitude,
    longitude: currentBranch.geolocation.longitude,
    productCode: trolleyStore.previewed_product_v2.code,
    requiredQuantity
  });

  stockStore.is_nearby_stock_search_loading = false;
}

async function handleTrolleyPageNavigation() {
  hideAllListingPageModals();
  const route = useRoute();
  if (route.path.includes("trolley")) {
    const windowRef = useWindowScroll({
      behavior: "smooth",
    });
    windowRef.y.value = 0;
    return;
  }
  await navigateTo(localePath("/trolley"));
}

//-------------------- Monetate Action
function getMonetateAddons(){
  useMonetateStore().getProductAddons()
}

</script>
